import React from "react";
import Layout from "../components/layout";

const SuccessPage = () => (
  <Layout>
    <h1>Form Submitted</h1>
    <p>Thank you for your submission.</p>
    <p>
      <a href="/">Click HERE</a> to return to where you were.
    </p>
  </Layout>
);

export default SuccessPage;
